<template>
  <div class="c-footer">
     Томск, Партизанская 8В
    <a class="c-footer__link" href="tel:+73822211755">
      +7 (3822) 21-17-55
    </a>
  </div>
</template>

<script setup lang="ts" />

<style scoped lang="scss">
.c-footer {
  width: 100%;
  padding: 12px 16px;
  background-color: #399ade;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-radius: 8px 8px 0 0;

  @include responsive(md) {
    font-size: 20px;
  }

  &__link {
    color: #fff;
  }
}
</style>